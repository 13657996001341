import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { updateTheme } from "../modules/SecuredPanel/utils/themeStore";
import Tooltip from '@mui/material/Tooltip';

export default function ThemeToggler(props) {
 
    

    // const setTheme = (theme) => {
    //     let prevTheme = sessionStorage.getItem('theme');

    //         prevTheme = theme;

    //     sessionStorage.setItem('theme', prevTheme);
    // }
    const dispatch = useDispatch();

    useEffect(() => {
        onPageLoad();
    }, [])
    

    const onPageLoad = () => {
        sessionStorage.getItem('theme') === null? dispatch(updateTheme('light')): sessionStorage.getItem('theme') == "light" ? dispatch(updateTheme('light')) : dispatch(updateTheme('dark'));
    }
      

    
    const toggle = () => {
        
        props.themeMode === "light" ? dispatch(updateTheme('dark')) && sessionStorage.setItem('theme', "dark") : dispatch(updateTheme('light')) && sessionStorage.setItem('theme', "light");
        //console.log('theme-session: ', sessionStorage.getItem('theme'))
    }



    return (
        
        <Tooltip title="Change Theme" arrow>  
        <button className="btn btn-sm toggler-button border border-white p-2" 
                        onClick={toggle}>
                        {props.themeMode === 'light' ?
                         <span aria-label="Dark mode" role="img">
                             <i className="fa-moon fa" id="dark-mode-toggler"></i>
                         </span>:
                         <span aria-label="Light mode" role="img">
                        <i className="fas fa-sun" id="dark-mode-toggler"></i>
                        </span>}
                        </button>
                        </Tooltip>
       
    )
}