import React from 'react';
import Loader from 'react-loader-spinner';


const primary = localStorage.getItem("jsp-primary")

const Loading = (props) => {
  return (
    <div className="flex flex-col text-center justify-center items-center">
    <Loader className="loader" type="Circles" color={primary} height="40" width="40"/>
    
      <p className="txt1">
        Please wait...
      </p>
    </div>
  )
}

export default React.memo(Loading);