import $ from 'jquery';
import { Link } from 'react-router-dom';
import { ErrorReport } from './ErrorPreportDialog';
import data from '../modules/SecuredPanel/utils/constant';

export const ShowInactive = props => {
    return(
        <>
         <div className="d-flex justify-content-center py-4">
        <div className="card-wrong bg-white d-flex w-75 h-100 justify-content-center align-items-center flex-column text-center my-4 p-5 rounded">
            <div className="error-img">
                <i className="fa fa-times-circle fa-5x text-danger"></i>
            </div>
            
            <h3 className="pt-3 fw-bold">School Inactive!</h3>

             <p className="text-primary">{data?.school?.name} Portals are currently inactive, please check back later.</p>


                <div className="justify-content-center d-block mt-2">
                <ErrorReport errorMessage={'portal showing inactive'} />
                </div>
            
           
        </div>
        </div>
        </>
    )
}