import $ from 'jquery';
import { Link } from 'react-router-dom';
import { ErrorReport } from './ErrorPreportDialog';

export const ShowDialog = props => {
    return(
        <>
         <div className="d-flex justify-content-center py-4">
        <div className="card-wrong bg-white d-flex w-75 h-100 justify-content-center align-items-center flex-column text-center my-4 p-5 rounded">
            <div className="error-img">
                <i className="fa fa-exclamation-triangle fa-5x text-primary"></i>
            </div>
            
            <h3 className="pt-3 fw-bold">Network Error!</h3>

             <p className="text-primary">Please check your network connection and try again.</p>


                <div className="justify-content-center d-block mt-2">
                <ErrorReport errorMessage={props.error.message} />
                </div>
            
           
                <button
                className={`text-white mb-2 fs-4 btn btn-primary`}
                onClick={() => window.location.reload()}
            >
                Reload this page
            </button>
        </div>
        </div>
        </>
    )
}