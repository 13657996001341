import { createSlice } from '@reduxjs/toolkit'

export const schoolDetailsStore = createSlice({
  name: 'school',
  initialState: {
    value: {},
  },
  reducers: {
    updateSchool: (state, action) => {
      state.value = action.payload
    },
    defaultSchool: (state, action) => {
        state.value = {}
    }
  },
})

// Action creators are generated for each case reducer function
export const { updateSchool, defaultSchool } = schoolDetailsStore.actions

export default schoolDetailsStore.reducer