
// let applicant = localStorage.getItem('__applicant') != undefined ? JSON.parse(localStorage.getItem('__applicant')) : null;
let token = localStorage.getItem('__token') != undefined ? JSON.parse(localStorage.getItem('__token')) : null;
let school = localStorage.getItem('school') != undefined ? JSON.parse(localStorage.getItem('school')) : null;

const environment = window.location.hostname;
let host;
let printhost;
let envArr = ['localhost', 'eum.tespire.co', 'uni-staging.tespire.co'];

console.log(environment);


if (envArr.includes(environment)) {
  host = 'https://staging-api.jspnigeria.com';
  printhost = 'https://staging-api.tespire.ng';
} else {
  host = 'https://api.jspnigeria.com';
  printhost = 'https://api.tespire.ng';
}
const applicantApiRoute = `${host}/applicationportal/applicant`;

async function fetchApplicantData() {

   if(token == null) {
       return null;
     }
     const response = await fetch(applicantApiRoute, {
       method: 'GET',
       headers: {
         Authorization: 'Bearer '+token,
         xtenant: environment === 'localhost' ? 'uni-staging.tespire.co' : environment,
        //  xtenant: 'uni-staging.tespire.co',
       },
     });
   
     const applicantData = await response.json();
     return applicantData.data.responseBody;
}

let applicant = await fetchApplicantData();

const data = {
    school: school,
    applicant: applicant != undefined ? applicant : "",
    host: host,
    // host: 'http://192.168.105.116:8005',
    cloud: 'https://filesupload.jdlabserver.com',
    // printHost: 'https://www.jsp.ng/',
    // printHost: 'https://api.tespire.ng',
    // printHost: 'https://staging-api.tespire.ng',
    printHost: printhost,
    token: token != undefined ? token : '',
    // id: applicant != undefined ? applicant.id : '',
    headers: {
        Authorization: 'Bearer ' + (token != undefined ? token : ''),
        // xtenant: window.location.hostname
        xtenant: environment === 'localhost' ? 'uni-staging.tespire.co' : environment
        // xtenant: 'uni-staging.tespire.co'
    }
} 




export default data 
