import { useNavigate } from "react-router-dom";
import { ErrorReport } from "./ErrorPreportDialog";
import errorImg from "./assets/img/code_error.png";

const host = window.location.host;
const path = window.location.pathname;

export default function ErrorComponent (props) { 
    const navigate = useNavigate();
    
    const goBack = async () => {
        await navigate(-1);
        setTimeout(() => {
            window.location.reload()
        }, 1000);
    }
    return (
        <>
        <div className="d-flex justify-content-center py-4">
        <div className="card-wrong bg-white d-flex w-75 h-100 justify-content-center align-items-center flex-column text-center my-4 p-5 rounded">
            <div className="error-img">
                {/* <img src={errorImg} alt="" height={200} className="wrong-img my-5"/> */}
                <i className="fa fa-exclamation-triangle fa-5x text-primary"></i>
            </div>
            
            <h3 className="pt-3 fw-bold">Oops! Sorry, Something went wrong.</h3>
            {/* {props.error && <p className="text-muted">{props.error.message}</p>} */}

             <p className="text-primary">Report this issue, so we can work on it.</p>

             <ErrorReport errorMessage={props.error.message} />
                <div className="justify-content-center d-block mt-2">
                <a href={'#'} onClick={() => goBack()} className="wrong-anchor ">    
                    <p className="btn-hero btn-primary p-2 w-100 mb-3 bg-transparent border-0 text-white">
                    Go back to previous page
                    </p> 
                </a>
                </div>
            
           
        </div>
        </div>


        </>
        
    );
    
};