import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { lightTheme, darkTheme } from "./themes";
import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "./globalstyles";
import ThemeToggler from "./ThemeToggler";
import useDocumentTitle from "./useDocumentTitle";


export default function DomainNotTaken(props) {

    const themeMode = useSelector((state) => state.theme.value);
    // const [themeMod, setThemeMod] = useState({theme: themeMode});

    let themeModeProp = {
        themeMode
    }

    const [loading, setLoading] = useState(false)

    useDocumentTitle("Tespire | Domain Not Taken")

    const onSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        window.location = 'https://agetn-john-wyssjb.firebaseapp.com'
    }

    const Loaderview = () => {
        return null;
    }

    return (
        <ThemeProvider theme={themeMode === 'light' ? lightTheme : darkTheme}>
            <GlobalStyles />
            <>
                <div id="app">
                    <section className="section">
                        <div className="container">
                            <ThemeToggler {...themeModeProp} />

                            <div className="container mt-5">
                                <div className="row">
                                    <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                                        <div className="card login-card">
                                            <div className="card-header">
                                                <h3 className="text-primary">This domain has not been taken yet, interested?</h3>
                                            </div>

                                            <div className="card-body">
                                                <form
                                                    noValidate
                                                    className="needs-validation"
                                                    onSubmit={onSubmit}
                                                >


                                                    <div className="form-group-button" >
                                                        {loading ? Loaderview() : <button
                                                            type="submit"
                                                            className="btn btn-lg btn-block w-100 my-3 bg-primary"
                                                            tabIndex="4"
                                                        >
                                                            Take this domain
                                                        </button>}


                                                    </div>
                                                </form>


                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>

                    </section>
                </div>
            </>
        </ThemeProvider>
    );
}
