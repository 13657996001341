import Axios from 'axios';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import $ from 'jquery';
import data from '../modules/SecuredPanel/utils/constant';
import Loader from '../modules/SecuredPanel/components/shared/loader';
import { Link, useNavigate } from 'react-router-dom';
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { useRef } from 'react';
import { postDataFunc } from '../modules/SecuredPanel/pages/dashboard/Compositions/ResourceDataPost';

const host = window.location.host;
const path = window.location.pathname;
let description;

export const ErrorReport = ({ errorMessage }) => {

    const [imageDetails, setImageDetails] = useState({ fileName: 'Attach a screenshot', file: '' });
    const [state, setState] = useState(0);
    const [response, setResponse] = useState('');
    const navigate = useNavigate();
    const screen1 = useRef(null);
    const screen2 = useRef(null);
    const nodeRef = state === 0 ? screen1 : screen2;

    const [loading, setLoading] = useState(false);

    useEffect(() => {
    }, [description]);

    const handleImageUpload = (e) => {
        const tgt = e.target || window.Event.target,
            files = tgt.files;

        // FileReader support
        if (FileReader && files && files.length) {
            const fr = new FileReader();
            //eslint-disable-next-line
            fr.onload = () => setImageDetails({ fileName: files[0]?.name, file: files[0] });
            fr.readAsDataURL(files[0]);
        }
    }

    const handleSubmit = async () => {

        setLoading(true);
        const details = {
            subject: `${errorMessage} : (${host}${path})`,
            message: description,
            file: imageDetails.file
        };

        Axios
            .post(`${data.host}/error/report`, details, {
                headers: data.headers
            })
            .then(response => {
                if (response.data.error) {
                    return setLoading(false)
                }
                setResponse(response.data.responseBody);
                setLoading(false);
                setState(state => ++state);


            })
            .catch(error => {
                setLoading(false)
            });



    };

    const handleChange = (e) => {
        //console.log(e.target.value)
        description = e.target.value;
    }

    const goBack = async () => {

        await navigate(-1);
        setTimeout(() => {
            window.location.reload()
        }, 1000);
    }

    const Screen1 = () => {

        return (
            <>
                <div className="col-12 mb-3">
                    <div className="col-8">
                        <label>Optional(Tell us more about the problem)</label>
                    </div>
                    <textarea className='form-control form-control-alt' value={description} placeholder='Please Include your Application/Matric Number' onChange={e => handleChange(e)} maxLength={3000} rows={8}></textarea>
                </div>
                <div className="col-5 mb-2">
                    <input name="image" id="image" type="file" style={{ display: "none" }} onChange={e => handleImageUpload(e)} className="form-control" accept="image/png, image/jpeg" />
                    <label htmlFor="image" className="btn btn-primary"><i className="fa fa-paperclip"></i>{imageDetails.fileName}</label>
                </div>
                <div className='modal-footer'>
                    {loading ? <Loader /> : <button
                        onClick={handleSubmit}
                        type="button"
                        className="btn btn-primary"
                    >
                        Submit
                    </button>}
                </div>
            </>
        )
    }

    const Screen2 = () => {

        return (
            <>
                <div className="m-2">

                    <div className="mb-4 mt-2 text-left text-black">
                        <p>{response} If the issue persists, Please kindly send us an email to <a href="mailto: support@tespire.co" className="text-primary"><u>support@tespire.co</u></a>.</p>
                    </div>
                </div>
                <div className='modal-footer'>
                    <button
                        onClick={() => goBack()}
                        type="button"
                        className="btn btn-primary"
                    >
                        Go back to previous page
                    </button>
                </div>
            </>
        )
    }


    return (
        <div>
            <Link to={'#'}
                className={`text-primary mb-2 fs-4`}
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
            >
                <u>Report Problem</u>
            </Link>

            <div
                className="modal fade"
                id="exampleModal"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header bg-white">
                            <h5 className="modal-title" id="exampleModalLabel">
                                Report A Problem
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <SwitchTransition mode={'out-in'}>
                            <CSSTransition
                                key={state}
                                nodeRef={nodeRef}
                                addEndListener={(done) => {
                                    nodeRef.current.addEventListener("transitionend", done, false);
                                }}
                                classNames='fade'
                            >
                                <div ref={nodeRef}>

                                    <div className="modal-body">
                                        {state === 0 ? <Screen1 /> : <Screen2 />}
                                    </div>
                                </div>
                            </CSSTransition>
                        </SwitchTransition>

                    </div>
                </div>
            </div>
        </div>
    );
}
