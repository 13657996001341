import { createSlice } from '@reduxjs/toolkit'
import data from './constant'
//console.log(data.school.current_session.id)

export const sessionStore = createSlice({
  name: 'sessionId',
  initialState: {
    value: data?.school?.current_session?.id,
  },
  reducers: {
    updateSessionId: (state, action) => {
      state.value = action.payload
    },
    defaultSessionId: (state, action) => {
        state.value = data?.school?.current_session?.id
    }
  },
})

// Action creators are generated for each case reducer function
export const { updateSessionId, defaultSessionId } = sessionStore.actions

export default sessionStore.reducer