import React from 'react';
import Loader from 'react-loader-spinner';




const pageLoad = (props) => {

  const primary = localStorage.getItem("jsp-primary")

  return (
    <div className='full-loader d-flex w-100 h-100 justify-content-center align-items-center'>
          <div className="flex flex-col text-center justify-center items-center">
    <Loader className="loader" type="Circles" color={primary} height="40" width="40"/>
    
      <p className="txt1">
        Please wait...
      </p>
    </div>
      
    </div>
    
    //
  )
}

export default pageLoad