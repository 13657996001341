import { configureStore } from '@reduxjs/toolkit'
import sessionStore from './sessionStore'
import themeStore from './themeStore'
import schoolDetailsStore from './schoolDetailsStore'
import subjectsStore from './subjectsStore';
import selectedSubjectsStore from './selectedSubjectsStore';

export default configureStore({
  reducer: {
    sessionId: sessionStore,
    theme: themeStore,
    school: schoolDetailsStore,
    subjects: subjectsStore,
    selectedSubjects: selectedSubjectsStore,
  },
});