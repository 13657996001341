import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';
import * as jQuery from "jquery";
import { BrowserRouter } from "react-router-dom";
import './index.css';
import axios from 'axios';
// import modules from './modules';
import App from './App';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { createStore } from 'redux';
import * as serviceWorker from './serviceWorker';
// import { school, updateSchool } from './store/school/school';
// import { updateUser } from './store/user/user';
// import infoCombine from './store';
import { Suspense } from "react";
import Loading from './modules/ApplicationPortal/Pages/Features/loader';
import PageLoad from './modules/ApplicationPortal/Pages/Features/pageLoad';
import {ThemeProvider} from "styled-components";
import { GlobalStyles } from "./global-components/globalstyles";
import { lightTheme, darkTheme } from "./global-components/themes"
import icon from "./global-components/assets/img/ico.ico"
import "./global-components/assets/css/dashmix.css";
import "./global-components/assets/css/customStyle.css";
import DomainNotTaken from './global-components/DomainNotTaken';
import SessionTimeout from './global-components/SessionTimeout';
import ErrorBoundary from './global-components/ErrorBoundary';
import ButtonComponent from './global-components/ErrorBoundary'; 
import data from './modules/ApplicationPortal/components/constants';
import store from './modules/SecuredPanel/utils/store';
import { updateSchool } from './modules/SecuredPanel/utils/schoolDetailsStore';
import { object } from 'prop-types';
import { ShowInactive } from './global-components/InactiveComponent';
import { createRoot } from 'react-dom/client';

const fetch = require('sync-fetch')
let host = window.location.hostname, url;

// if(host === 'localhost') url = 'http://127.0.0.1:8000/api/school-info';else url = 'https://api.jspnigeria.com/api/school-info';
if (host === 'localhost' || host === 'eum.tespire.co' || host === 'uni-staging.tespire.co') {
  url = 'https://staging-api.jspnigeria.com/api/school-info';
}
else {
  url = 'https://api.jspnigeria.com/api/school-info';
}


if (host == 'localhost') {
  host = data.headers.xtenant
}

let school_info
 // if (!sessionStorage.getItem('school')) {
  school_info = fetch(url, { headers: { xtenant: host } }).json();

  localStorage.setItem('school', JSON?.stringify(school_info))
 //} else {
 //  school_info = JSON.parse(sessionStorage.getItem('school'))
 // }


//const store = createStore(infoCombine);
//const favicon = document.getElementById("favicon");

 store.dispatch(updateSchool(school_info));
        // sch = JSON.stringify(store.getState());
  //favicon.href = `${school_info?.logo}`;
        
  localStorage.setItem("jsp-primary", school_info.status == 'false' ? '#330077' : school_info.primary_color)

window.jQuery = jQuery;
window.$ = jQuery; 

const primary = localStorage.getItem("jsp-primary")

if (localStorage.getItem("jsp-primary") != null) {
   document.documentElement.style.setProperty("--jsp-primary", primary);
   document.documentElement.style.setProperty("--jsp-primary-opaque", primary +"c0");
   document.documentElement.style.setProperty("--jsp-primary-hover", primary +"c9");
}

document.querySelectorAll('iframe').forEach(
  function(elem){
    elem.parentNode.removeChild(elem);
});

window.JSZip = require("jszip");
require("datatables.net-bs4/css/dataTables.bootstrap4.min.css");
require("datatables.net-bs4");
require("datatables.net-buttons-bs4");
require("datatables.net-buttons/js/buttons.html5");
require("datatables.net-buttons/js/buttons.print");
require("datatables.net-buttons/js/buttons.colVis");
require("datatables.net-buttons/js/buttons.flash")
require("pdfmake/build/pdfmake.min");
require("pdfmake/build/vfs_fonts");
require("datatables.net-responsive");
require("datatables.net-responsive-bs4");
require("datatables.net-select");
require("datatables.net-select-bs4");
require("prismjs/themes/prism.css");
require("pdfmake/build/vfs_fonts");
require('jszip');
require('pdfmake');
// require('jquery');

 
// require("./global-components/assets/js/dashmix.app.min.js");
var moment = require("moment/min/moment.min.js");

window.moment = moment;
window.JSZip = require("jszip");

const domain = window.location.hostname;

const rootSrc = document.getElementById("root");
const root = createRoot(rootSrc);
root.render(
  <BrowserRouter>
    <React.StrictMode>
      <Provider store={store}>
        {/* <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
      <GlobalStyles/> */}
      <ErrorBoundary>
        {/* <ButtonComponent /> */}
        <Suspense fallback={<PageLoad/>}>
          {school_info.status == false ? <DomainNotTaken/> : school_info.status == 'inactive' ? <ShowInactive/> : <App />}
        </Suspense>
      </ErrorBoundary>
       
         {/* </ThemeProvider> */}
        </Provider>
    </React.StrictMode>{" "}
    {/* <SessionTimeout/> */}
  </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
