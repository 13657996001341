import { createSlice } from '@reduxjs/toolkit'

export const themeStore = createSlice({
  name: 'theme',
  initialState: {
    value: 'light',
  },
  reducers: {
    updateTheme: (state, action) => {
      state.value = action.payload
    },
    defaultTheme: (state, action) => {
        state.value = 'light'
    }
  },
})

// Action creators are generated for each case reducer function
export const { updateTheme, defaultTheme } = themeStore.actions

export default themeStore.reducer