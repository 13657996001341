import React from "react";
import ErrorComponent from "./ErrorComponent";
import data from "../modules/ApplicationPortal/components/constants";
import { ShowDialog } from "./showDialog";

export default class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
    error: { message: "", stack: "" },
    info: { componentStack: "" }
  };

  static getDerivedStateFromError = error => {
    return { hasError: true };
  };

  // componentDidMount() {
  //   console.log(data.school)
  // }

  componentDidCatch = (error, info) => {
    this.setState({ error, info });
  };

  render() {
    const { hasError, error, info } = this.state;
    const { children } = this.props;

    return hasError ? data.school === undefined || data.school === null?
    <ShowDialog error={error} info={info}/>
    : <ErrorComponent error={error} info={info}/> : children;
  }
}