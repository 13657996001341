import { createSlice } from '@reduxjs/toolkit'

export const subjectsStore = createSlice({
  name: 'subjects',
  initialState: {
    value: [],
  },
  reducers: {
    updateSubject: (state, action) => {
      state.value = action.payload
    },
    defaultSubjects: (state, action) => {
      state.value = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { updateSubject, defaultSubjects } = subjectsStore.actions

export default subjectsStore.reducer