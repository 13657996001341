// import modules from './modules';
import { lazy } from "react";
//import './App.css'
const fetch = require('sync-fetch');
let App, domain, portal_id, prev_url, location, path_split, prefix;

domain = window.location.hostname == 'localhost'? 'eum.tespire.co' : window.location.hostname;
// const school = useSelector((state) => state.school.value);
let hostUrl;
let envArr = ['localhost', 'eum.tespire.co', 'uni-staging.tespire.co'];

if (envArr.includes(window.location.hostname)) {
  hostUrl = 'https://staging-api.jspnigeria.com';
} else {
  hostUrl = 'https://api.jspnigeria.com';
}
// let school = sessionStorage.getItem('school') != undefined ? JSON.parse(sessionStorage.getItem('school')) : fetch('https://api.jspnigeria.com/api/school-info', { headers: { xtenant: domain } }).json();
let school = sessionStorage.getItem('school') != undefined ? JSON.parse(sessionStorage.getItem('school')) : fetch(`${hostUrl}/api/school-info`, { headers: { xtenant: domain } }).json();

const Application = lazy(() => import("./modules/ApplicationPortal"));
const SecuredPanel = lazy(() => import("./modules/SecuredPanel"));
const Students = lazy(() => import("./modules/StudentsPortal"));
const GenLanding = lazy(() => import("./global-components/landing"));


let portal_ids = [];



school?.portals?.map((portal,i) => {
    portal_ids?.push(portal.portal_id)
});

prev_url = document.referrer;

location = window.location.pathname;

path_split = location.split("/");
prefix = path_split[1];


if (!domain.match(/application|students|securedpanel/g) && location == '/' && !prev_url.includes('/auth/login')) {
    domain = location;
    sessionStorage.removeItem('portal_id');
}

portal_id = sessionStorage.getItem('portal_id') ?? null;

if (prefix.includes('application') || portal_id == '1') {
    App = Application;
    portal_id = '1';
} else if (prefix.includes('students') || portal_id == '2') {
    App = Students;
    portal_id = '2';
} else if (prefix.includes('securedpanel') || portal_id == '3') {
    App = SecuredPanel;
    portal_id = '3';
} else if (domain == '/') {   
    App = GenLanding; 
}

if (!portal_ids?.includes(portal_id)) {
    App = GenLanding;
}



// switch (portal_id) {
//     case value:
        
//         break;
//     case value:
        
//         break;
//     case value:
        
//         break;
//     case value:
        
//         break;

//     default:
//         break;
// }

// indexr(__dirname).then((err, result) => {
//     console.log('Generated Successfully');
// });

// recursiveGenerateExportFile({
//     rootDirectory: path.resolve(__dirname, "./modules"),
//     fileExts: ["js"],
//     generatedFileExt: "js",
//     exclude: []
// });



export default App;
